/*
 * @Author: ringo
 * @Date: 2021-08-31 15:30:14
 * @LastEditTime: 2021-11-25 15:31:45
 * @LastEditors: your name
 * @Description:
 * @FilePath: \foundao_work\xiangan_enterprise\src\components\loading\index.tsx
 */
// 全局loading
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './loaders.min.css';
import './index.less';
export default function Index() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="xa-loading">
      <div className="loader-inner line-scale-pulse-out-rapid color">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {/* <Spin tip="数据加载中" indicator={antIcon} /> */}
    </div>
  );
}
